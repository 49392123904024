.wrapper
    width: 100%
    max-width: 1024px
    height: 100%
    margin: 0 auto
    overflow: hidden

    +media(mobile)
        width: 92%

    +media(tablet)
        width: 86%

    +media(desktop)
        width: 100%
        max-width: 1024px