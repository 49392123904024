*
    box-sizing: border-box

html, body
    height: 100%
    min-height: 400px

body
    font-family: "Gotham SSm A", "Gotham SSm B"
    font-size: 62.5%
    font-weight: 400
    font-style: normal
    background: $white
    color: $brown
a
    text-decoration: none

h1
    font-size: 6em
    font-weight: 500

h2
    font-size: 1.3em
    line-height: 1.4em
    text-transform: uppercase
    font-weight: 500

h3
    font-size: 1.4em

h4
    font-size: 1.2em

p
    font-size: 1.3em
    line-height: 1.8em

.ir
    display: block
    width: 0
    height: 0
    white-space: nowrap
    background: red
    overflow: hidden