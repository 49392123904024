.faq-item
    border-bottom: 1px dotted $medium-gray
    cursor: pointer

    &:hover
        // background: transparent url('/img/icons/bt-open.png') right 18px no-repeat
        // background-size: 24px


.faq-item__title
    padding: 20px 40px 20px 0px
    color: $brown
    opacity: .5

    &:hover
        opacity: 1

.faq-item__body
    overflow: hidden
    height: 0
    transition: all ease .3s

    p
        max-width: 640px
        padding: 5px 0 5px 40px
        border-left: 1px solid $brown
        margin-left: 2px
        color: $brown

    &.is-open
        padding: 10px 0px
        margin-bottom: 30px

.faq-item:last-child
    border-bottom: none