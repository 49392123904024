.thumb-item
    position: relative
    margin-bottom: 40px
    +media(mobile)
        margin-bottom: 20px

    +media(tablet)
        margin-bottom: 4%

.thumb-item__img
    width: 100%

.thumb-item__name
    color: $brown
    margin: 20px 0

    strong
        text-transform: uppercase
        display: block

.thumb-item__body
    margin-bottom: 10px
