////////////////
// Media Queries
////////////////

$mobile: "(max-width: 600px)"
$tablet: "(min-width: 601px) and (max-width: 980px)"
$desktop: "(min-width: 981px)"
$big-desktop: "(min-width: 1601px)"
$letterbox: "(max-height: 600px)"

////////////////
// Colors
////////////////

$white: #FFF
$light-gray: #f1f1e9
$medium-gray: #d7d3c7
$gray: #999
$dark-gray: #333
$black: #000
$brown: #68604c

////////////////
// Z-Index
////////////////

$z-close-video: 5
$z-video: 4
$z-menu: 3
$z-home-button: 1
$z-home-content: 2