.has-overlay
    overflow: hidden

.overlay
    display: none
    position: fixed
    z-index: $z-video
    transition: all .3s ease
    overflow: hidden
    top: 0
    left: 0
    right: 0
    bottom: 0
    border: 20px solid $white

    +media(mobile)
        border: 0

.overlay--show
    display: block
    opacity: 0

.overlay--show-animate
    opacity: 1

.overlay--hide
    display: block
    opacity: 1

.overlay--hide-animate
    opacity: 0

.overlay__bkg
    position: absolute
    z-index: 0
    top: 0
    left: 0
    width: 100%
    height: 100%
    background: $black

.overlay__close
    position: fixed
    top: 30px //-25px
    right: 30px //-25px
    width: 50px
    height: 50px
    z-index: $z-close-video
    background: transparent url('/img/icons/bt-video-close.png') center center no-repeat
    background-size: 40px
    border-radius: 50px
    transition: all .3s ease
    opacity: .5

    &:hover
        opacity: 1

.overlay__container
    position: absolute
    z-index: 1
    top: 50%
    left: 50%

.overlay__container--16_9
    left: 0
    width: 100%
    height: 0
    // padding-bottom: 60.93%
    // margin-top: -(60.93%/2)
    padding-bottom: 56.25%
    margin-top: -(56.25%/2)

.overlay__video
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

    iframe
        position: absolute
        top: 0
        left: 0
        width: 100%
        height: 100%

.overlay__container--16_9-based-on-height
    left: 0
    left: 50%
    top: 0
    height: 100%