////////////////
// BLOCK
////////////////

.row
    +cf

.col
    float: left

.col--is-right
    float: right

////////////////
// MODIFER
////////////////

.col--1_2
    width: 50%

// HOME
.col--home
    width: 33%
    height: 100%
    position: relative

    +media(tablet)
        width: 40%
    
    +media(mobile)
        width: 66%


// TECHNOLOGY
.col--1_3, .col--technologies
    width: 100%
    margin-bottom: 40px

    +media(tablet)
        width: 48%
        margin-left: 4%

        &:nth-child(2n + 1)
            clear: left
            margin-left: 0

    +media(desktop)
        width: 31.333%
        margin-left: 3%

        &:nth-child(3n + 1)
            margin-left: 0


// TEAM
.col--1_2, .col--team
    width: 100%

    +media(tablet)
        width: 46%
        margin-left: 4%

        &:nth-child(2n + 1)
            clear: left
            margin-left: 0

    +media(desktop)
        width: 46%
        margin-left: 4%

        &:nth-child(2n + 1)
            clear: left
            margin-left: 0


// CONTACT
.col--contact
    width: 65%
    display: inline-block

    +media(tablet)
       width: 100%
       margin-bottom: 40px

    +media(mobile)
       width: 100%
       margin-bottom: 40px


.col--urgences
    width: 30%
    float: right

    +media(tablet)
        width: 100%
        float: left

    +media(mobile)
        width: 100%
        float: left


.col.credits
    width: 100%
    display: block
    text-align: center
    padding-top: 60px
    line-height: 1.5em


