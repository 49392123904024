.icon
    display: inline-block
    position: relative
    width: 50px
    height: 50px

.icon-arrow-down-transparent
    background: transparent url('/img/icons/arrow-down-transparent.png') center center no-repeat
    background-size: 40px
    margin-top: 0px

.icon-arrow-down
    background: transparent url('/img/icons/arrow-down.png') center center no-repeat
    background-size: 40px

.icon-arrow-up
    background: transparent url('/img/icons/arrow-up.png') center center no-repeat
    background-size: 40px
