.treatments-list
    position: relative

.treatment-item--detail
    position: relative
    clear: both
    width: 100%
    overflow: hidden
    padding-bottom: 30px

.treatment-item--detail--hide
    position: absolute
    z-index: 1
    width: 100%

.treatment-item
    float: left
    position: relative

    +media(mobile)
        width: 100%

    +media(tablet)
        width: 50%

    +media(desktop)
        width: 33.333%

.treatment-item__name
    cursor: pointer
    min-height: 70px
    line-height: 1.4em
    margin-right: 20px
    padding: 10px 0px
    color: $brown
    text-transform: uppercase
    text-align: left
    opacity: .6

    +media(mobile)
        padding: 20px 0px
        min-height: 10px
  

    &:hover
        opacity: 1
        &:before
            height: 3px
            background: $brown

    &:before
        content: ""
        position: absolute
        height: 1px
        left: 0
        top: 0
        right: 20px
        background: $gray
        transition: all .2s ease


    .treatment-item--is-selected &
        &:before
            background: $brown
            height: 3px

        // border-top: 1px solid transparent
        opacity: 1


.treatment-item__figure
    float: left
    width: 33.3%

    +media(tablet)
        width: 50%
        padding-right: 30px

    +media(mobile)
        float: none
        width: 100%

.treatment-item__img
    width: 100%
    max-width: 240px
    display: block
    margin-bottom: 20px
    margin-top: 10px

    +media(tablet)
        max-width: 100%

    +media(mobile)
        max-width: 100%



.treatment-item__caption
    width: 180px
    font-size: 1.2em
    line-height: 1.5em
    color: $gray

    +media(mobile)
        width: 100%
        margin-bottom: 10px


.treatment-item__body
    margin-left: 33.3%
    min-height: 200px

    +media(tablet)
        margin-left: 50%

    +media(mobile)
        margin-left: 0
        min-height: 0

    p

        color: $brown
        margin-bottom: 15px
