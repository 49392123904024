=media($point)
  @if $point == mobile
    @media #{$mobile}
      @content

  @elseif $point == letterbox
    @media #{$letterbox}
      @content

  @else if $point == tablet
    @media #{$tablet}
      @content

  @else if $point == desktop
    @media #{$desktop}
      @content

  @else if $point == big-desktop
    @media #{$big-desktop}
      @content

=cf()
  &:after
    content: ""
    display: table
    clear: both

=font($type: normal)
  @if($type == normal)
    font-family: '#{$font}', Helvetica, Arial

  @if($type == bold)
    font-family: '#{$font-bold}', Helvetica, Arial

  @if($type == book)
    font-family: '#{$font-book}', Helvetica, Arial

  font-style: normal
  font-weight: normal