$height-nav: 100px

.burger
    width: 45px
    height: 45px
    padding: 4px
    position: fixed
    text-align: center
    z-index: 999
    left: 20px
    top: 20px
    background: url('/img/icons/burger.png') center center no-repeat
    background-size: 45px
    color: $brown
    display: none
    background-color: $white
    border: 2px solid transparent
    border-radius: 3px

    +media(mobile)
        display: block

    &:hover
        cursor: pointer
        background-color: transparent
        border: 2px solid white
        color: $white
        background: $brown url('/img/icons/burger-hover.png') center center no-repeat
        background-size: 45px

.nav
    position: fixed
    width: 100%
    z-index: $z-menu
    left: 0px
    top: 0px
    background: transparent
    transition: all .2s ease
    border-left: 0
    border-right: 0
    transition: all .3s ease

    +media(mobile)
        top: 0px
        left: 0px
        margin: 0
        width: 100%

.nav--is-invert
    // background: rgba(255,255,255, .8)
    background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC42Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=)
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.2) 0%, rgba(0,0,0,0) 100%) /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0 ,0,0,0.2)), color-stop(100%,rgba(0,0,0,0))) /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 100%) /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 100%) /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 100%) /* IE10+ */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.2) 0%,rgba(0,0,0,0) 100%) /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#68604c', endColorstr='#68604c',GradientType=0 ) /* IE6-8 */

    +media(mobile)
        top: 0px

.menu
    text-align: center
    
    +media(mobile)
        width: 100%
        height: 100%
        min-height: 1024px
        margin-top: -1000px
        transition: padding .3s ease, opacity .3s ease
        padding: 10% 16% 16% 16%
        background: $brown
        opacity: 0
        z-index: 0

.menu.open
    
    +media(mobile)
        opacity: 1
        margin-top: 0px
        padding: 16%


.menu .menu__item
    display: inline-block

    +media(mobile)
        width: 100%
        clear: both
        background-color: $brown
        text-align: left
        border-bottom: 1px solid rgba(255,255,255,.15)

.menu .menu__item:last-child
    border-bottom: none
        

.menu .logo
    display: none
    margin-bottom: 30px
    
    img
        max-width: 100%

    +media(mobile)
        display: block
        width: 85%
        margin-bottom: 40px

.menu__link
    display: block
    line-height: $height-nav
    padding: 0 10px
    font-size: 1.3em
    font-weight: 700
    text-transform: uppercase
    color: $white
    opacity: .8

    &:hover
        opacity: 1

    +media(mobile)
        color: $white
        font-size: 1.4em
        padding: 0px
        line-height: 36px
        

    .nav--is-invert &
        // color: $black

        +media(mobile)
            color: $white

    .menu__item--is-active &
        opacity: 1
        span
            border-bottom: 2px solid $white
            padding-bottom: 1px

        +media(mobile)
            color: $white

            &:before
                content: "— "

            span
                border-bottom: none
                padding-bottom: 0
            
        .nav--is-invert &
            // color: $black

            +media(mobile)
                color: $white

            span
                border-bottom: 2px solid $white

                +media(mobile)
                    border-bottom: 2px solid $brown
