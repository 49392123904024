////////////////
// Block
////////////////

.section
    float: left
    width: 100%
    background: $light-gray
    border: 20px solid $light-gray
    border-bottom: 0
    top: 0
    bottom: 0
    left: 0
    right: 0
    overflow: hidden

    +media(mobile)
        border: none

#home.section
    +media(mobile)
        background-color: $black

#home .section__btn
    width: 50px !important
    left: 50% !important
    margin-left: -25px !important

#treatments.section
    // min-height: 1800px
    +media(mobile)
        min-height: inherit

#faq.section
    // min-height: 2200px
    +media(mobile)
        min-height: inherit

        

////////////////
// ELEMENTS
////////////////

.section__img
    background-repeat: no-repeat
    background-size: cover
    background-attachment: scroll
    background-position: center top
    background-color: $light-gray
    top: 0px
    bottom: 0px
    width: 100%

    +media(mobile)
        background-attachment: scroll

    #home &
        background-image: url('/img/sections/home.jpg')
        height: 100%

        +media(mobile)
            opacity: .9
            height: 100% !important

    #treatments &
        background-image: url('/img/sections/treatments.jpg')

    #team &
        background-image: url('/img/sections/team.jpg')

    #technologies &
        background-image: url('/img/sections/technologies.jpg')

    #contact &
        background-image: url('/img/sections/contact.jpg')

    #faq &
        background-image: url('/img/sections/faq.jpg')

.section__content
    margin-top: -100px
    background: #F6F6F1
    padding: 0 60px 60px

    +media(mobile)
        padding: 0 30px 30px
        margin-top: -60px

    #home &
        width: 100%
        height: 100%
        background: transparent
        margin-top: 0
        position: absolute
        z-index: $z-home-content
        padding: 0
        top: 0px
        left: 0px


.section__title
    font-size: 4em
    color: $brown
    line-height: 120px
    letter-spacing: -1px

    +media(mobile)
        font-size: 3em
        line-height: 100px

.section__subtitle
    font-size: 1.6em
    line-height: 1.5em
    max-width: 625px
    color: $brown

.section__body--contact

    h2
        display: inline-block
        width: 100%
        margin-bottom: 20px
        text-transform: uppercase

    p
        max-width: 560px
        font-size: 1.3em
        line-height: 1.8em
        margin-bottom: 10px

        +media(tablet)
            max-width: 100%
    a
        color: $brown
        text-decoration: underline

        &:hover
            color: $black


.section__map-container
    position: relative
    width: 65%
    padding-top: (100/(16/9))/(100/75)+%
    float: left
    margin-bottom: 40px

    +media(tablet)
        width: 50%

    +media(mobile)
        width: 100%
        height: 260px
        margin-bottom: 40px

.section__map
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%

.section__address
    margin-left: 65%
    padding-left: 40px

    +media(tablet)
        width: 50%
        margin-left: 50%
        margin-bottom: 40px

    +media(mobile)
        width: 100%
        margin-left: 0
        padding: 0px
        margin-bottom: 20px
        text-align: center

    p
        line-height: 1.6em

        span
            display: inline-block
            width: 35px

    .btn
        margin-top: 10px


.urgences
    display: inline-block
    background-color: rgba(104,86,76,.1)
    padding: 20px
    border-radius: 6px
    margin-top: -20px

    p
        font-size: 1.2em
        margin: 0
        max-width: 100%

.section__btn
    padding-top: 20px
    height: 150px
    text-align: center
    line-height: 150px
    font-size: 50px
    position: relative
    z-index: 2 !important

    a
        display: block
        opacity: .25
        transition: all .2s ease

        &:hover
            opacity: 1

    #home &
        position: absolute
        z-index: $z-home-button
        bottom: 0
        left: 0
        width: 100%
        height: 150px
        line-height: 150px
        padding-top: 0

        .icon
            vertical-align: middle

////////////////
// MODIFER
////////////////

.section--is-light
    background: $white
    border-top-color: $light-gray