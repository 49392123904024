.btn
    display: inline-block
    font-size: 1.3em
    font-weight: 500
    text-transform: uppercase
    padding: 15px 30px
    border-radius: 3px

.btn-outline
    border: 2px solid $white
    background: $white
    transition: all .2s ease
    white-space: nowrap

.btn-outline--is-black
    border-color: $brown
    color: $white !important
    background-color: $brown
    text-decoration: none !important
        
    +media(mobile)
        margin: 40px 0px
        
    &:hover
        color: $brown !important
        background: transparent


.btn-outline--is-white
    color: $brown

    &:hover
        color: $white
        border-color: white
        background: transparent
