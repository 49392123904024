.block
    height: 100%

.block-home
    position: absolute
    width: 100%
    top: 50%
    margin-top: -200px
    left: 100px
    z-index: 10

    +media(tablet)
        left: 60px
        margin-top: -150px

    +media(mobile)
        left: 20px
        margin-top: -150px

    +media(letterbox)
        margin-top: -100px

.block-home__logo
    margin-bottom: 100px
    max-width: 420px
    width: 100%
    display: block
    height: auto

    +media(tablet)
        margin-bottom: 60px

    +media(mobile)
        margin-bottom: 40px

    +media(letterbox)
        max-width: 320px
        margin-bottom: 40px

.block-home__body
    margin-bottom: 30px
    width: 100%
    max-width: 300px
    font-size: 1.3em
    line-height: 1.5em
    color: $white

    +media(mobile)
        max-width: 100%
        display: none

.block-home__btn