@charset "UTF-8";
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after, q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

html, body {
  height: 100%;
  min-height: 400px; }

body {
  font-family: "Gotham SSm A", "Gotham SSm B";
  font-size: 62.5%;
  font-weight: 400;
  font-style: normal;
  background: #FFF;
  color: #68604c; }

a {
  text-decoration: none; }

h1 {
  font-size: 6em;
  font-weight: 500; }

h2 {
  font-size: 1.3em;
  line-height: 1.4em;
  text-transform: uppercase;
  font-weight: 500; }

h3 {
  font-size: 1.4em; }

h4 {
  font-size: 1.2em; }

p {
  font-size: 1.3em;
  line-height: 1.8em; }

.ir {
  display: block;
  width: 0;
  height: 0;
  white-space: nowrap;
  background: red;
  overflow: hidden; }

.wrapper {
  width: 100%;
  max-width: 1024px;
  height: 100%;
  margin: 0 auto;
  overflow: hidden; }
  @media (max-width: 600px) {
    .wrapper {
      width: 92%; } }
  @media (min-width: 601px) and (max-width: 980px) {
    .wrapper {
      width: 86%; } }
  @media (min-width: 981px) {
    .wrapper {
      width: 100%;
      max-width: 1024px; } }

.burger {
  width: 45px;
  height: 45px;
  padding: 4px;
  position: fixed;
  text-align: center;
  z-index: 999;
  left: 20px;
  top: 20px;
  background: url('/img/icons/burger.png') center center no-repeat;
  background-size: 45px;
  color: #68604c;
  display: none;
  background-color: #FFF;
  border: 2px solid transparent;
  border-radius: 3px; }
  @media (max-width: 600px) {
    .burger {
      display: block; } }
  .burger:hover {
    cursor: pointer;
    background-color: transparent;
    border: 2px solid white;
    color: #FFF;
    background: #68604c url('/img/icons/burger-hover.png') center center no-repeat;
    background-size: 45px; }

.nav {
  position: fixed;
  width: 100%;
  z-index: 3;
  left: 0px;
  top: 0px;
  background: transparent;
  transition: all .2s ease;
  border-left: 0;
  border-right: 0;
  transition: all .3s ease; }
  @media (max-width: 600px) {
    .nav {
      top: 0px;
      left: 0px;
      margin: 0;
      width: 100%; } }

.nav--is-invert {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMC42Ii8+CiAgPC9saW5lYXJHcmFkaWVudD4KICA8cmVjdCB4PSIwIiB5PSIwIiB3aWR0aD0iMSIgaGVpZ2h0PSIxIiBmaWxsPSJ1cmwoI2dyYWQtdWNnZy1nZW5lcmF0ZWQpIiAvPgo8L3N2Zz4=);
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0.2)), color-stop(100%, transparent));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#68604c', endColorstr='#68604c',GradientType=0 ) /* IE6-8 */; }
  @media (max-width: 600px) {
    .nav--is-invert {
      top: 0px; } }

.menu {
  text-align: center; }
  @media (max-width: 600px) {
    .menu {
      width: 100%;
      height: 100%;
      min-height: 1024px;
      margin-top: -1000px;
      transition: padding .3s ease, opacity .3s ease;
      padding: 10% 16% 16% 16%;
      background: #68604c;
      opacity: 0;
      z-index: 0; } }

@media (max-width: 600px) {
  .menu.open {
    opacity: 1;
    margin-top: 0px;
    padding: 16%; } }

.menu .menu__item {
  display: inline-block; }
  @media (max-width: 600px) {
    .menu .menu__item {
      width: 100%;
      clear: both;
      background-color: #68604c;
      text-align: left;
      border-bottom: 1px solid rgba(255, 255, 255, 0.15); } }

.menu .menu__item:last-child {
  border-bottom: none; }

.menu .logo {
  display: none;
  margin-bottom: 30px; }
  .menu .logo img {
    max-width: 100%; }
  @media (max-width: 600px) {
    .menu .logo {
      display: block;
      width: 85%;
      margin-bottom: 40px; } }

.menu__link {
  display: block;
  line-height: 100px;
  padding: 0 10px;
  font-size: 1.3em;
  font-weight: 700;
  text-transform: uppercase;
  color: #FFF;
  opacity: .8; }
  .menu__link:hover {
    opacity: 1; }
  @media (max-width: 600px) {
    .menu__link {
      color: #FFF;
      font-size: 1.4em;
      padding: 0px;
      line-height: 36px; } }
  @media (max-width: 600px) {
    .nav--is-invert .menu__link {
      color: #FFF; } }
  .menu__item--is-active .menu__link {
    opacity: 1; }
    .menu__item--is-active .menu__link span {
      border-bottom: 2px solid #FFF;
      padding-bottom: 1px; }
    @media (max-width: 600px) {
      .menu__item--is-active .menu__link {
        color: #FFF; }
        .menu__item--is-active .menu__link:before {
          content: "— "; }
        .menu__item--is-active .menu__link span {
          border-bottom: none;
          padding-bottom: 0; } }
    @media (max-width: 600px) {
      .nav--is-invert .menu__item--is-active .menu__link {
        color: #FFF; } }
    .nav--is-invert .menu__item--is-active .menu__link span {
      border-bottom: 2px solid #FFF; }
      @media (max-width: 600px) {
        .nav--is-invert .menu__item--is-active .menu__link span {
          border-bottom: 2px solid #68604c; } }

.btn {
  display: inline-block;
  font-size: 1.3em;
  font-weight: 500;
  text-transform: uppercase;
  padding: 15px 30px;
  border-radius: 3px; }

.btn-outline {
  border: 2px solid #FFF;
  background: #FFF;
  transition: all .2s ease;
  white-space: nowrap; }

.btn-outline--is-black {
  border-color: #68604c;
  color: #FFF !important;
  background-color: #68604c;
  text-decoration: none !important; }
  @media (max-width: 600px) {
    .btn-outline--is-black {
      margin: 40px 0px; } }
  .btn-outline--is-black:hover {
    color: #68604c !important;
    background: transparent; }

.btn-outline--is-white {
  color: #68604c; }
  .btn-outline--is-white:hover {
    color: #FFF;
    border-color: white;
    background: transparent; }

.section {
  float: left;
  width: 100%;
  background: #f1f1e9;
  border: 20px solid #f1f1e9;
  border-bottom: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden; }
  @media (max-width: 600px) {
    .section {
      border: none; } }

@media (max-width: 600px) {
  #home.section {
    background-color: #000; } }

#home .section__btn {
  width: 50px !important;
  left: 50% !important;
  margin-left: -25px !important; }

@media (max-width: 600px) {
  #treatments.section {
    min-height: inherit; } }

@media (max-width: 600px) {
  #faq.section {
    min-height: inherit; } }

.section__img {
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: scroll;
  background-position: center top;
  background-color: #f1f1e9;
  top: 0px;
  bottom: 0px;
  width: 100%; }
  @media (max-width: 600px) {
    .section__img {
      background-attachment: scroll; } }
  #home .section__img {
    background-image: url('/img/sections/home.jpg');
    height: 100%; }
    @media (max-width: 600px) {
      #home .section__img {
        opacity: .9;
        height: 100% !important; } }
  #treatments .section__img {
    background-image: url('/img/sections/treatments.jpg'); }
  #team .section__img {
    background-image: url('/img/sections/team.jpg'); }
  #technologies .section__img {
    background-image: url('/img/sections/technologies.jpg'); }
  #contact .section__img {
    background-image: url('/img/sections/contact.jpg'); }
  #faq .section__img {
    background-image: url('/img/sections/faq.jpg'); }

.section__content {
  margin-top: -100px;
  background: #F6F6F1;
  padding: 0 60px 60px; }
  @media (max-width: 600px) {
    .section__content {
      padding: 0 30px 30px;
      margin-top: -60px; } }
  #home .section__content {
    width: 100%;
    height: 100%;
    background: transparent;
    margin-top: 0;
    position: absolute;
    z-index: 2;
    padding: 0;
    top: 0px;
    left: 0px; }

.section__title {
  font-size: 4em;
  color: #68604c;
  line-height: 120px;
  letter-spacing: -1px; }
  @media (max-width: 600px) {
    .section__title {
      font-size: 3em;
      line-height: 100px; } }

.section__subtitle {
  font-size: 1.6em;
  line-height: 1.5em;
  max-width: 625px;
  color: #68604c; }

.section__body--contact h2 {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  text-transform: uppercase; }
.section__body--contact p {
  max-width: 560px;
  font-size: 1.3em;
  line-height: 1.8em;
  margin-bottom: 10px; }
  @media (min-width: 601px) and (max-width: 980px) {
    .section__body--contact p {
      max-width: 100%; } }
.section__body--contact a {
  color: #68604c;
  text-decoration: underline; }
  .section__body--contact a:hover {
    color: #000; }

.section__map-container {
  position: relative;
  width: 65%;
  padding-top: 42.1875%;
  float: left;
  margin-bottom: 40px; }
  @media (min-width: 601px) and (max-width: 980px) {
    .section__map-container {
      width: 50%; } }
  @media (max-width: 600px) {
    .section__map-container {
      width: 100%;
      height: 260px;
      margin-bottom: 40px; } }

.section__map {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.section__address {
  margin-left: 65%;
  padding-left: 40px; }
  @media (min-width: 601px) and (max-width: 980px) {
    .section__address {
      width: 50%;
      margin-left: 50%;
      margin-bottom: 40px; } }
  @media (max-width: 600px) {
    .section__address {
      width: 100%;
      margin-left: 0;
      padding: 0px;
      margin-bottom: 20px;
      text-align: center; } }
  .section__address p {
    line-height: 1.6em; }
    .section__address p span {
      display: inline-block;
      width: 35px; }
  .section__address .btn {
    margin-top: 10px; }

.urgences {
  display: inline-block;
  background-color: rgba(104, 86, 76, 0.1);
  padding: 20px;
  border-radius: 6px;
  margin-top: -20px; }
  .urgences p {
    font-size: 1.2em;
    margin: 0;
    max-width: 100%; }

.section__btn {
  padding-top: 20px;
  height: 150px;
  text-align: center;
  line-height: 150px;
  font-size: 50px;
  position: relative;
  z-index: 2 !important; }
  .section__btn a {
    display: block;
    opacity: .25;
    transition: all .2s ease; }
    .section__btn a:hover {
      opacity: 1; }
  #home .section__btn {
    position: absolute;
    z-index: 1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 150px;
    line-height: 150px;
    padding-top: 0; }
    #home .section__btn .icon {
      vertical-align: middle; }

.section--is-light {
  background: #FFF;
  border-top-color: #f1f1e9; }

.row:after {
  content: "";
  display: table;
  clear: both; }

.col {
  float: left; }

.col--is-right {
  float: right; }

.col--1_2 {
  width: 50%; }

.col--home {
  width: 33%;
  height: 100%;
  position: relative; }
  @media (min-width: 601px) and (max-width: 980px) {
    .col--home {
      width: 40%; } }
  @media (max-width: 600px) {
    .col--home {
      width: 66%; } }

.col--1_3, .col--technologies {
  width: 100%;
  margin-bottom: 40px; }
  @media (min-width: 601px) and (max-width: 980px) {
    .col--1_3, .col--technologies {
      width: 48%;
      margin-left: 4%; }
      .col--1_3:nth-child(2n+1), .col--technologies:nth-child(2n+1) {
        clear: left;
        margin-left: 0; } }
  @media (min-width: 981px) {
    .col--1_3, .col--technologies {
      width: 31.333%;
      margin-left: 3%; }
      .col--1_3:nth-child(3n+1), .col--technologies:nth-child(3n+1) {
        margin-left: 0; } }

.col--1_2, .col--team {
  width: 100%; }
  @media (min-width: 601px) and (max-width: 980px) {
    .col--1_2, .col--team {
      width: 46%;
      margin-left: 4%; }
      .col--1_2:nth-child(2n+1), .col--team:nth-child(2n+1) {
        clear: left;
        margin-left: 0; } }
  @media (min-width: 981px) {
    .col--1_2, .col--team {
      width: 46%;
      margin-left: 4%; }
      .col--1_2:nth-child(2n+1), .col--team:nth-child(2n+1) {
        clear: left;
        margin-left: 0; } }

.col--contact {
  width: 65%;
  display: inline-block; }
  @media (min-width: 601px) and (max-width: 980px) {
    .col--contact {
      width: 100%;
      margin-bottom: 40px; } }
  @media (max-width: 600px) {
    .col--contact {
      width: 100%;
      margin-bottom: 40px; } }

.col--urgences {
  width: 30%;
  float: right; }
  @media (min-width: 601px) and (max-width: 980px) {
    .col--urgences {
      width: 100%;
      float: left; } }
  @media (max-width: 600px) {
    .col--urgences {
      width: 100%;
      float: left; } }

.col.credits {
  width: 100%;
  display: block;
  text-align: center;
  padding-top: 60px;
  line-height: 1.5em; }

.thumb-item {
  position: relative;
  margin-bottom: 40px; }
  @media (max-width: 600px) {
    .thumb-item {
      margin-bottom: 20px; } }
  @media (min-width: 601px) and (max-width: 980px) {
    .thumb-item {
      margin-bottom: 4%; } }

.thumb-item__img {
  width: 100%; }

.thumb-item__name {
  color: #68604c;
  margin: 20px 0; }
  .thumb-item__name strong {
    text-transform: uppercase;
    display: block; }

.thumb-item__body {
  margin-bottom: 10px; }

.faq-item {
  border-bottom: 1px dotted #d7d3c7;
  cursor: pointer; }

.faq-item__title {
  padding: 20px 40px 20px 0px;
  color: #68604c;
  opacity: .5; }
  .faq-item__title:hover {
    opacity: 1; }

.faq-item__body {
  overflow: hidden;
  height: 0;
  transition: all ease .3s; }
  .faq-item__body p {
    max-width: 640px;
    padding: 5px 0 5px 40px;
    border-left: 1px solid #68604c;
    margin-left: 2px;
    color: #68604c; }
  .faq-item__body.is-open {
    padding: 10px 0px;
    margin-bottom: 30px; }

.faq-item:last-child {
  border-bottom: none; }

.treatments-list {
  position: relative; }

.treatment-item--detail {
  position: relative;
  clear: both;
  width: 100%;
  overflow: hidden;
  padding-bottom: 30px; }

.treatment-item--detail--hide {
  position: absolute;
  z-index: 1;
  width: 100%; }

.treatment-item {
  float: left;
  position: relative; }
  @media (max-width: 600px) {
    .treatment-item {
      width: 100%; } }
  @media (min-width: 601px) and (max-width: 980px) {
    .treatment-item {
      width: 50%; } }
  @media (min-width: 981px) {
    .treatment-item {
      width: 33.333%; } }

.treatment-item__name {
  cursor: pointer;
  min-height: 70px;
  line-height: 1.4em;
  margin-right: 20px;
  padding: 10px 0px;
  color: #68604c;
  text-transform: uppercase;
  text-align: left;
  opacity: .6; }
  @media (max-width: 600px) {
    .treatment-item__name {
      padding: 20px 0px;
      min-height: 10px; } }
  .treatment-item__name:hover {
    opacity: 1; }
    .treatment-item__name:hover:before {
      height: 3px;
      background: #68604c; }
  .treatment-item__name:before {
    content: "";
    position: absolute;
    height: 1px;
    left: 0;
    top: 0;
    right: 20px;
    background: #999;
    transition: all .2s ease; }
  .treatment-item--is-selected .treatment-item__name {
    opacity: 1; }
    .treatment-item--is-selected .treatment-item__name:before {
      background: #68604c;
      height: 3px; }

.treatment-item__figure {
  float: left;
  width: 33.3%; }
  @media (min-width: 601px) and (max-width: 980px) {
    .treatment-item__figure {
      width: 50%;
      padding-right: 30px; } }
  @media (max-width: 600px) {
    .treatment-item__figure {
      float: none;
      width: 100%; } }

.treatment-item__img {
  width: 100%;
  max-width: 240px;
  display: block;
  margin-bottom: 20px;
  margin-top: 10px; }
  @media (min-width: 601px) and (max-width: 980px) {
    .treatment-item__img {
      max-width: 100%; } }
  @media (max-width: 600px) {
    .treatment-item__img {
      max-width: 100%; } }

.treatment-item__caption {
  width: 180px;
  font-size: 1.2em;
  line-height: 1.5em;
  color: #999; }
  @media (max-width: 600px) {
    .treatment-item__caption {
      width: 100%;
      margin-bottom: 10px; } }

.treatment-item__body {
  margin-left: 33.3%;
  min-height: 200px; }
  @media (min-width: 601px) and (max-width: 980px) {
    .treatment-item__body {
      margin-left: 50%; } }
  @media (max-width: 600px) {
    .treatment-item__body {
      margin-left: 0;
      min-height: 0; } }
  .treatment-item__body p {
    color: #68604c;
    margin-bottom: 15px; }

.icon {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px; }

.icon-arrow-down-transparent {
  background: transparent url('/img/icons/arrow-down-transparent.png') center center no-repeat;
  background-size: 40px;
  margin-top: 0px; }

.icon-arrow-down {
  background: transparent url('/img/icons/arrow-down.png') center center no-repeat;
  background-size: 40px; }

.icon-arrow-up {
  background: transparent url('/img/icons/arrow-up.png') center center no-repeat;
  background-size: 40px; }

.block {
  height: 100%; }

.block-home {
  position: absolute;
  width: 100%;
  top: 50%;
  margin-top: -200px;
  left: 100px;
  z-index: 10; }
  @media (min-width: 601px) and (max-width: 980px) {
    .block-home {
      left: 60px;
      margin-top: -150px; } }
  @media (max-width: 600px) {
    .block-home {
      left: 20px;
      margin-top: -150px; } }
  @media (max-height: 600px) {
    .block-home {
      margin-top: -100px; } }

.block-home__logo {
  margin-bottom: 100px;
  max-width: 420px;
  width: 100%;
  display: block;
  height: auto; }
  @media (min-width: 601px) and (max-width: 980px) {
    .block-home__logo {
      margin-bottom: 60px; } }
  @media (max-width: 600px) {
    .block-home__logo {
      margin-bottom: 40px; } }
  @media (max-height: 600px) {
    .block-home__logo {
      max-width: 320px;
      margin-bottom: 40px; } }

.block-home__body {
  margin-bottom: 30px;
  width: 100%;
  max-width: 300px;
  font-size: 1.3em;
  line-height: 1.5em;
  color: #FFF; }
  @media (max-width: 600px) {
    .block-home__body {
      max-width: 100%;
      display: none; } }

.has-overlay {
  overflow: hidden; }

.overlay {
  display: none;
  position: fixed;
  z-index: 4;
  transition: all .3s ease;
  overflow: hidden;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 20px solid #FFF; }
  @media (max-width: 600px) {
    .overlay {
      border: 0; } }

.overlay--show {
  display: block;
  opacity: 0; }

.overlay--show-animate {
  opacity: 1; }

.overlay--hide {
  display: block;
  opacity: 1; }

.overlay--hide-animate {
  opacity: 0; }

.overlay__bkg {
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000; }

.overlay__close {
  position: fixed;
  top: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  z-index: 5;
  background: transparent url('/img/icons/bt-video-close.png') center center no-repeat;
  background-size: 40px;
  border-radius: 50px;
  transition: all .3s ease;
  opacity: .5; }
  .overlay__close:hover {
    opacity: 1; }

.overlay__container {
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%; }

.overlay__container--16_9 {
  left: 0;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  margin-top: -28.125%; }

.overlay__video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }
  .overlay__video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.overlay__container--16_9-based-on-height {
  left: 0;
  left: 50%;
  top: 0;
  height: 100%; }

